
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>智能化用户标签体系构建</h3>
      <p>
        这是一把开启数字化、智能化、自动化营销的钥匙，是营销发展史上第一次实现AI智能化商品推荐的核心环节
      </p>
      <button @click="onapply()">申请试用</button>
    </div>

    <div class="content">
      <img src="../../assets/images/member03.png" />
      <div>
        <h6>基于微心动一体化营销云的用户标签体系</h6>
        <p>
          营销的核心和本质是真正了解自己的客户，并通过恰当的渠道和时机将品牌和商品价值持续传递给客户，这一个极为复杂的营销工程，由于流程中存在着众多变量和未知，传统营销手段已经无法精准地执行和达成效果。<br />
          微心动营销云通过建立体系化的多维度标签，将客户属性特征、客户偏好、营销内容、商品品类、沟通反馈等标签化，用智能化自动化的营销流程为数以亿计的客户群体带来美妙的个性化营销体验。用精准标签体系驱动营销运营和决策，从而将AI智能技术与营销创意、客户心智进行融合，触达营销核心本质，实现业务高速增长。
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="cost">
      <h4 class="head">建立标签体系的营销业务价值</h4>
      <div class="cost_c">
        <div>
          <img src="../../assets/images/label01.png" />
          <h6>精准客户洞察</h6>
          <p>
            利用标签细分消费者，洞察客户画像与行为特征，寻找高价值沟通触点与沟通模式
          </p>
        </div>
        <div>
          <img src="../../assets/images/label02.png" />
          <h6>增强客户体验</h6>
          <p>
            确保客户收到正确的感兴趣的营销内容，并可实现跨部门与客户的双向沟通和反馈
          </p>
        </div>
        <div>
          <img src="../../assets/images/label03.png" />
          <h6>提高运营效率</h6>
          <p>
            根据标签体系建立精准客户分层，将个性化内容的路径流程配置与节点标签记录，提高营销运营效率
          </p>
        </div>
        <div>
          <img src="../../assets/images/label04.png" />
          <h6>推动价值转化</h6>
          <p>
            根据客户标签的组合建模进行分析和预测，提高Cross sell/Up
            sell的精准度和营销效果
          </p>
        </div>
        <div>
          <img src="../../assets/images/label05.png" />
          <h6>广告效果提升</h6>
          <p>
            标签系统可回流精准DMP人群包，与AD
            Hub平台结合可实现广告的精准投放和效果监测
          </p>
        </div>
        <div>
          <img src="../../assets/images/label06.png" />
          <h6>开放接口和标签库</h6>
          <p>
            微心动营销云具有成熟完毕的开放接口，将形成的标签库通过数据接口输出到第三方系统
          </p>
        </div>
      </div>
    </div>
    <div class="merit">
      <h5>丰富的营销运用场景</h5>
      <div class="merit_c">
        <p :class="number == 1 ? 'xz' : ''" @click="onmerit(1)">
          智能化商品推荐
        </p>
        <p :class="number == 2 ? 'xz' : ''" @click="onmerit(2)">完善客户画像</p>
        <p :class="number == 3 ? 'xz' : ''" @click="onmerit(3)">
          赋能全生命周期
        </p>
      </div>
      <div class="recommend" v-show="number == 1">
        <p>根据客户标签进行聚类，让客户愉快地购买你的商品或服务</p>
        <img src="../../assets/images/recommend.png" alt="" />
      </div>
      <div class="recommend2" v-show="number == 2">
        <p>
          通过标签体系的建设和沉淀不断完善用户画像，全面了解客户的兴趣和偏好，支持战略性营销决策和企业发展。
        </p>
        <img src="../../assets/images/recommend02.png" alt="" />
      </div>
      <div class="recommend3" v-show="number == 3">
        <div>
          <h6>根据标签体系沉淀客户资产，第一次前所未有地看清楚你的客户</h6>
          <p>让你第一次拥有全渠道营销和全场景会员营销能力</p>
          <p>
            通过对客户生命周期、客户商品偏好、购买偏好、沟通偏好和购买预测的拆解和标记，形成颗粒度精细的标签体系，利用算法架构标签来建立深度用户洞察和客户分层。
          </p>
          <p>
            无论客户在什么地方，不管是线下还是线上，你都可以快速准确的与客户进行精准的沟通。即便你面对的是数以亿计的客户群体，我们的营销自动化流程通过精准的标签体系，也能让瞬息间发生的亿万次营销触达，犹如面对面沟通一样精准而亲切。
          </p>
        </div>
        <img src="../../assets/images/recommend03.png" alt="" />
      </div>
    </div>
    <div class="adviser">
      <h5>标签体系顾问服务</h5>
      <p>
        为您提供令人震撼的标签体系建设平台，也为您提供专业的标签体系顾问服务
      </p>
      <img src="../../assets/images/adviser.png" alt="" />
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      number: 2,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onmerit(i) {
      this.number = i;
    },
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization06.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  display: flex;
  justify-content: center;
  padding: 0.68rem 0;
  //   background-color: #fffff5;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 6.32rem;
    padding-left: 0.88rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.16rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.content:nth-child(4) {
  background-color: #fffff5;
}

.cost {
  padding: 1.1rem 0 1.4rem;
  .head {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.12rem;
  }
  .cost_c {
    padding: 0.18rem 3.4rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      padding-top: 0.32rem;
      width: 4rem;
      text-align: center;
      img {
        width: 0.68rem;
        height: 0.68rem;
      }
      h6 {
        font-size: 0.22rem;
        padding-bottom: 0.3rem;
      }
      p {
        width: 3.1rem;
        margin: 0 auto;
        font-size: 0.18rem;
        line-height: 0.27rem;
        color: #666;
      }
    }
  }
}

.merit {
  width: 100%;
  height: 9.84rem;
  box-sizing: border-box;
  background-image: url(../../assets/images/merit_bj.png);
  background-size: 100% 100%;
  color: #fff;
  h5 {
    padding: 0.9rem 0 0.36rem;
    font-size: 0.28rem;
    line-height: 0.5rem;
    text-align: center;
  }
  .merit_c {
    width: 14.4rem;
    margin: 0 auto;
    display: flex;
    border-bottom: 0.01rem solid #fff;
    p {
      flex: 1;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.77rem;
      color: #666;
    }
    .xz {
      color: #fff;
      border-bottom: 0.03rem solid #fff;
    }
  }
  .recommend {
    text-align: center;
    animation-name: move1;
  animation-duration: 3s;
  overflow: hidden;
    p {
      font-size: 0.22rem;
      padding: 0.48rem 0 0.06rem;
    }
    img {
      width: 11.02rem;
      height: 6rem;
    }
  }
  .recommend2 {
    text-align: center;
        animation-name: move1;
  animation-duration: 3s;
  overflow: hidden;
    p {
      font-size: 0.22rem;
      padding: 0.48rem 0 0.06rem;
    }
    img {
      width: 14.16rem;
      height: 6.08rem;
    }
  }
  .recommend3 {
    text-align: center;
        animation-name: move1;
  animation-duration: 3s;
  overflow: hidden;
    div {
      text-align: left;
      width: 13.58rem;
      margin: 0 auto;
      padding: 0.44rem 0 0;
      h6 {
        font-size: 0.24rem;
        font-weight: lighter;
        padding-bottom: 0.12rem;
      }
      p {
        font-size: 0.2rem;
        padding-top: 0.14rem;
        line-height: 0.3rem;
        font-weight: lighter;
        color: #ddd;
      }
    }

    img {
      margin-top: 0.2rem;
      width: 14.16rem;
      height: 3.06rem;
    }
  }
}
@keyframes move1 {
  /* 0%的内容可以不写，也可以直接不写0% */
  0% {
   height: 0;
  }
  25% {
    // transform: translatex(-1000px, 0);
  }
  50% {
  }
  75% {
  }
  100% {
    height: 100%;
    // transform: translate(1000px, 0);
  }
}
.adviser {
  text-align: center;
  h5 {
    padding-top: 0.68rem;
    font-size: 0.28rem;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
  }
  img {
    padding: 0.58rem 0 1rem;
    width: 15.86rem;
    height: 2.02rem;
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
